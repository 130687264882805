<template>
  <div class="btn">
    <el-button type="primary">开始抽选</el-button>
  </div>
</template>

<script>
export default {
  // 准备就绪
  name: '',
  props: {},
};
</script>

<style scoped lang="scss">
.btn {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  border-top: 1px solid $border1;
  .el-button {
    margin: auto;
  }
}
</style>
