<template>
  <section class="component-onProgress">
    <div class="circle">
      <canvas></canvas>
    </div>
    <div class="main">
      <div class="item">
        <div class="label">姓名：</div>
        <div class="data">张三</div>
      </div>
      <div class="item">
        <div class="label">性别：</div>
        <div class="data">男</div>
      </div>
      <div class="item">
        <div class="label">身份证号码：</div>
        <div class="data">421202199909092569</div>
      </div>
      <div class="item">
        <div class="label">常居住地：</div>
        <div class="data">张三</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  // 进行中
  name: '',
  props: {},
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.component-onProgress {
  display: flex;
  .circle {
    flex: 1;
  }
  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .item {
      display: flex;
      padding-top: 10px;
      .label {
        width: 84px;
        text-align: right;
      }
    }
  }
}
</style>
