<template>
  <el-dialog
    :title="title"
    :visible.sync="show"
    :close-on-click-modal="false"
    class="choose-randomCandidate-usualLive-randomSelect-component"
  >
    <div class="main">
      <div class="item">
        <div class="label">选任单位</div>
        <div class="data">中原区司法局</div>
      </div>
      <div class="item">
        <div class="label">年度</div>
        <div class="data">2018</div>
      </div>
      <div class="item">
        <div class="label">选任标题</div>
        <div class="data">选任计划</div>
      </div>
      <div class="item">
        <div class="label">计划人数</div>
        <div class="data">1000</div>
      </div>
      <div class="item">
        <div class="label">计划时间</div>
        <div class="data">2018-11-27 至 2018-11-30</div>
      </div>
      <div class="item">
        <div class="label">候选人员总数</div>
        <div class="data">420</div>
      </div>
      <div class="item">
        <div class="label">已抽取人数</div>
        <div class="data">3</div>
      </div>
      <div class="item">
        <div class="label">可抽取人数</div>
        <div class="data">42</div>
      </div>
      <div class="item">
        <div class="label">抽选来源</div>
        <el-select v-model="data1" placeholder="请选择抽选来源" size="mini">
          <el-option label="中原司法局" value="0"></el-option>
          <el-option label="南昌司法局" value="1"></el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="label">拟抽选人数</div>
        <el-input v-model="data3" placeholder="请输入拟抽选人数" size="mini"></el-input>
      </div>
    </div>
    <div class="select">
      <component :is="componentName"></component>
    </div>
  </el-dialog>
</template>

<script>
import ready from './component/ready';
import onProgress from './component/onProgress';
import result from './component/result';
export default {
  // 随机抽取
  name: 'checkCode',
  props: {
    title: {
      tyle: String,
    },
  },
  components: {
    ready,
    onProgress,
    result,
  },
  data() {
    return {
      show: false,
      src: 'https://jxgh.jxtongke.com/uploads/20211015162238.mp41.jpg',
      data1: '',
      data3: '',
      componentName: 'onProgress',
    };
  },
  methods: {
    toggle(show) {
      this.show = show;
    },
    beforeClose(done) {
      console.log(done);
      // done(false);
      return false;
      // if (1) {
      //   return false;
      // } else {
      //   // 不满足条件时允许关闭，如果缺少下面的写法，就无法关闭对话框了

      //   return done(true);
      // }
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__title {
  font-weight: bold;
}
.el-dialog {
  min-width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .main {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    .item {
      width: 50%;
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      box-sizing: border-box;
      .label {
        width: 100px;
        text-align: right;
        padding-right: 20px;
      }
      .data {
        flex: 1;
      }
      .el-input {
        width: 50%;
      }
      .el-select {
        width: 50%;
      }
    }
  }
}
</style>
