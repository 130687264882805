<template>
  <el-table
    :data="table.data"
    default-expand-all
    row-key="id"
    v-loading="table.loading"
    :header-cell-style="{'text-align':'center','background-color':'#FAFAFA'}"
    :cell-style="{'text-align':'center'}"
  >
    <el-table-column prop="index" label="序号"></el-table-column>
    <el-table-column prop="index" label="姓名"></el-table-column>
    <el-table-column prop="index" label="性别"></el-table-column>
    <el-table-column prop="index" label="身份证号码" width="180"></el-table-column>
    <el-table-column prop="index" label="联系方式"></el-table-column>
    <el-table-column prop="index" label="常住地址"></el-table-column>
  </el-table>
</template>

<script>
export default {
  // 结果
  name: '',
  props: {},
  components: {},
  data() {
    return {
      table: {
        data: [],
      },
    };
  },
  methods: {},
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
</style>
